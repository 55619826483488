<template>
  <div>本协议发布时间：2022年9月30日</div>
  <div>本协议更新时间：2022年9月30日</div>
  <div class="mb-6">章易签由一览数字科技(江苏)有限公司(下称“一览数字”)负责运营。本协议双方为一览数字与章易签注册用户(下称“用户”或“您”)，本协议具有合同效力，适用于您在本平台的全部活动。</div>
  <div class="mb-6 fw-b">特别声明：</div>
  <div class="mb-6">
    (一)为了更好地为您提供服务，请您仔细阅读《用户协议》(以下简称“本协议”)。在您开始使用“章易签”及本平台提供的相关服务之前，请您务必认真阅读并充分理解本协议，<span
      class="fw-b">特别是与您的权益有或可能有重大关系的条款，及对本平台具有或可能具有免责或限制责任的条款，上述重要内容以加粗形式提示您注意，您应重点阅读。</span>阅读本协议的过程中，如果您不同意本协议或其中任何条款，您有充分且完全的权利退出使用章易签，请您不要勾选本协议进行后续操作。<span
      class="fw-b">若您注册成为章易签用户，则表示您对本协议的全部内容已充分阅读并认可和同意遵守，本协议即在您与本平台之间产生法律效力。</span>同时，您承诺遵守中国法律、法规、规章及其他政府规范性文件的规定，如有违反而造成任何法律后果，您将以本人名义独立承担所有相应的法律责任。
  </div>
  <div class="mb-6">
    (二)本协议内容包括以下条款及已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的一部分，与协议正文具有同等法律效力。一览数字有权根据需要不定时地制定、修改本协议或各类规则，如本协议及规则有任何变更，一切变更以章易签或一览数字最新公布的内容为准。经修订的协议、规则一经公布，立即自动生效，对新协议、规则生效之后注册的用户发生法律效力。对于协议、规则生效之前注册的用户，<span
      class="fw-b">若用户在新规则生效后继续使用本网站提供的各项服务，则表明用户已充分阅读并认可和同意遵守新的协议或规则。若您拒绝接受新的协议和规则，您必须自行停止使用本平台提供的各项服务</span>(包括但不限于使用用户名和密码登录章易签、在章易签上进行发起或签约电子合同、制作电子签名、进行认证等)。
  </div>
  <div class="mb-6">(三)您承诺，在您同意接受本协议并注册成为章易签用户时，<span
      class="fw-b">您已经年满18周岁并具有完全民事行为能力，或者您是在中国大陆地区合法开展经营活动或其他业务的法人或其他组织，本协议内容不受您所属国家或地区法律的排斥。</span>不具备前述条件的，您应立即终止注册或停止使用本服务。您在使用章易签时，应自行判断对方是否具有完全民事行为能力，且确定对方提供的信息和其本人对应，并由您自行决定是否与对方进行签约交易等，您应自行承担与此相关的所有风险。章易签不承担因对方身份确认失误而造成的损失。
  </div>
  <div class="mb-6">(四)您保证，<span
      class="fw-b">在您同意接受本协议并注册成为章易签用户时，即表示您认可使用章易签作为缔约(包括但不限于电子文件)方式，认可您在章易签平台上的所有操作都是您个人意愿的表示，认可您在章易签平台上签订的电子文件(或协议)和纸质文件(或协议)具备相同的效力，认可您在章易签平台上的电子签名和您的手写签名具备相同的效力。</span>
  </div>
  <div class="mb-6">(五)您保证，遵守国家法律法规要求，不得将章易签用于危害国家安全、损害社会公众利益、侵犯他人合法权益等非法用途，若有违背，一览数字有权单方面采取措施并要求当事人承担相应的法律责任。
  </div>
  <div class="mb-6">(六)您确认并知悉，依据《电子签名法》涉及下列情形不适合使用电子文书：</div>
  <div class="mb-6">1、涉及婚姻、收养、继承等人身关系的；</div>
  <div class="mb-6">2、涉及停止供水、供热、供气、供电等公用事业服务的；</div>
  <div class="mb-6">3、法律、行政法规规定的不适用电子文书的其他情形。</div>
  <div class="mb-6">(七)如果您对本协议有任何的疑问、投诉、意见和建议，欢迎您通过本协议所附联系方式与一览数字沟通反馈，一览数字十分乐意为您解答</div>
  <div class="mb-6">本协议主要包括以下内容：</div>
  <div class="mb-6">(1)定义与解释</div>
  <div class="mb-6">(2)用户账号及使用</div>
  <div class="mb-6">(3)服务内容及费用</div>
  <div class="mb-6">(4)免责及限制条款</div>
  <div class="mb-6">(5)隐私制度</div>
  <div class="mb-6">(6)商标及知识产权</div>
  <div class="mb-6">(7)法律适用与争议解决</div>
  <div class="mb-20">(8)其他</div>
  <div class="mb-6 fw-b">一、定义与解释</div>
  <div class="mb-6">1、一览数字：即一览数字科技(江苏)有限公司，系章易签的经营者和服务提供者。</div>
  <div class="mb-6">2、章易签(章易签平台)：指章易签官方网站、章易签小程序等。</div>
  <div class="mb-6">3、章易签服务：指章易签以网站、应用程序、开放接口或其他形式提供的电子签名、身份认证、合同签署等各种产品及服务(具体以章易签实际提供的为准)。</div>
  <div class="mb-6">4、电子认证服务机构(CA机构)：指符合《电子签名法》规定，依法设立的电子认证服务提供者，具备《电子认证服务许可证》的第三方数字证书签发机构。</div>
  <div class="mb-6">
    5、电子签章：基于电子笔迹或电子印章信息，采用数字签名技术，实现对电子文件的安全保护和印章信息的可视化图形展现，可用以辨识电子文件签署者身份的真实性、签章行为的不可抵赖性，以及被签章文件的完整性。</div>
  <div class="mb-6">
    6、电子签名：基于数字签名技术实现的传统手写签字的电子化图像数据，具有数字化存储、拥有唯一标识、基于数字签名技术保护以及授权后方可使用等基本特征。章易签提供的签名图形为模板签名和手绘签名，模板签名指章易签根据数字证书持有人姓名/名称生成的电子签名图形；手绘签名图形指用户通过在传输介质上手绘形成的图形；
  </div>
  <div class="mb-6">7、电子签名认证证书(数字证书)：指由国家认可的第三方数字认证中心提供的、包含证书持有者身份信息以及公开密钥等相关信息，用于识别签名人身份信息的一串数据。</div>
  <div class="mb-6">8、时间戳：是指由第三方提供的具有法律效力的时间戳，证明某份电子数据在某个时间点已经存在，该时间点后的任何修改均能被发现。</div>
  <div class="mb-6 fw-b">二、用户账号及使用</div>
  <div class="mb-6">2.1章易签用户</div>
  <div class="mb-6">
    2.1.1章易签的用户包括企业用户和个人用户。企业用户是指通过章易签完成全部注册程序后，使用章易签提供的服务，并且依据中华人民共和国法律在境内(香港、台湾、澳门除外)设立的，能够独立承担民事责任的法人<span
      class="fw-b">和其他组织。</span>个人用户是指中华人民共和国境内(香港、台湾、澳门除外)公民，通过章易签完成全部注册程序后，使用章易签提供的服务，并且符合中华人民共和国法律规定的具有完全民事能力，能够独立承担民事责任的自然人。
  </div>
  <div class="mb-6">2.1.2
    您应当了解，章易签提供的主要服务为电子文件签署，如您未满18周岁，应在法定代理人的监护下成为章易签用户，或由法定代理人为您签署电子文件。如您未经监护人同意使用章易签服务，且您的监护人不愿意追认的，您应当立即通知您的监护人告知我们，要求我们适当处理您的个人信息并协助您妥善处理已经签署的电子文件。若因您不具备主体资格而导致的一切后果，由您及您的监护人自行承担。
  </div>
  <div class="mb-6">2.2章易签账号</div>
  <div class="mb-6">2.2.1是章易签向用户提供的唯一识别编号。您通过使用章易签账号接受章易签提供的各项服务并管理您在章易签的信息及数据。您可以使用<span
      class="fw-b">手机号码注册</span>，通过校验码回填等方式登录章易签账号。您还可以为章易签账号设置密码，作为账号登录方式。</div>
  <div class="mb-6">
    2.2.2您应准确真实地填写章易签要求您提供的注册信息，确保姓名、联系方式的真实性，以便章易签与您进行及时、有效地联系。您声明并保证，您在注册时填写的联系方式为您本人所控制，无论该联系方式是否在您本人名下，均能够体现您本人的意志。
  </div>
  <div class="mb-6">2.2.3 如您代表企业(含其他组织)注册，应当确保您已经获得被代表的企业(含其他组织)的合法授权，并确保其接受本协议的约束。</div>
  <div class="mb-6">
    2.3.1个人及企业成章易签用户后须进行实名认证。在实名认证过程中个人及企业必须根据章易签的要求提供最新、真实、有效及完整的资料。否则，您的电子签名(数字证书)操作将不受相关法律保护，如因您造假行为产生的相关法律责任均由您自行承担。根据实名认证方式的不同，个人所需提供的实名认证资料将有所差异，具体所需资料请以章易签要求为准。企业需要提供的实名认证资料包括但不限于营业执照、统一社会信用代码、授权委托书、法定代表人或代理人姓名及其身份证信息、电话号码等。
  </div>
  <div class="mb-6">
    2.3.2您同意并授权章易签平台代为提交实名认证材料审核，申请所需信息是由用户您本人自行提供的，故章易签无法保证该信息之准确、有效和完整。所提交的用户认证信息将用于核发其专属的电子签名文件，章易签承诺对用户的信息严格保密。用户信息及隐私保护详见《隐私政策》。
  </div>
  <div class="mb-6">2.3.3实名认证具体操作流程请严格按照章易签认证规定的流程履行，否则将影响实名审核的结果。</div>
  <div class="mb-6">2.4用户信息管理</div>
  <div class="mb-6">
    2.4.1您应按照章易签要求在注册成功后及时更新您真实完整的身份信息及相关资料。因您未及时更新资料导致的一切后果，均应由您自行承担，该后果包括但不限于导致本服务无法提供或提供时发生错误、账号及电子签名制作数据被别人盗用或给您的业务相对方带来损失的等。
  </div>
  <div class="mb-6 fw-b">2.4.2 您应当准确提供并及时更新您的联系方式，以章易签与您进行及时、有效的联系。通过您提供的联系方式无法与您取得联系而导致的您在使用本服务过程中遭受的任何损失由您自行承担。
  </div>
  <div class="mb-6 fw-b">2.4.3 如获得您的授权，章易签将把您签署的电子文件同步传输至被授权第三方平台，您可以在第三方平台上查看到您已签署的电子文件。</div>
  <div class="mb-6">
    2.4.4您同意，章易签有权按照包括但不限于公安机关、检察机关、法院、海关、税务机关等司法机关、行政机关、军事机关的要求对您的个人信息及在章易签的账户信息及您交易的账户等进行查询、冻结或其他操作。</div>
  <div class="mb-6">2.5账号注销</div>
  <div class="mb-6">2.5.1 您只能注销您本人注册的账号，并按照章易签规定的流程注销账号。</div>
  <div class="mb-6">2.5.2
    注销是不可恢复的操作，您将无法再使用该账号或通过该账号找回您添加或绑定在该账号上的内容，包括数字证书、手绘签名、电子签章、电子文件和签署记录相关信息，您应自行备份相关信息和数据。操作之前，请确认与该账号相关的所有数据均已妥善处理，因使用该账号所产生的相应权利义务已经终结或已被妥善处理。
  </div>
  <div class="mb-6">2.5.3 注销将导致章易签与您约定的双方的权利义务终止，同时还将产生以下后果：</div>
  <div class="mb-6">A. 任何您的交易信息都将无法查看；</div>
  <div class="mb-6">B. 任何您的交易过程中产生的附属文档都将无法查看、下载，您可以在注销之前进行保存；</div>
  <div class="mb-6">C. 注销完成后，无法对您之前留存下来的任何信息提供公证服务；</div>
  <div class="mb-6">D. 该账号的联系人将无法通过该账号联系您；</div>
  <div class="mb-6">E. 该账号的充值余额将不予退回。</div>
  <div class="mb-6">F. 注销账户后您的个人信息会保持不可被检索、访问的状态，但您在使用章易签产品/服务期间提供或产生的信息我们仍需按照监管要求的保存5年以上，且在保存的时间内依法配合有权机关的查询。
  </div>
  <div class="mb-6">
    2.5.4您申请注销的章易签账户应当处于正常状态，即您的账户信息和用户信息是最新、完整、正确的，且该账户可以使用所有章易签服务功能。账户信息或用户信息过时、缺失、不正确的账户或被暂停或终止提供服务的账户不能被申请注销。如您申请注销的账户有关联账户或子账户的，在该关联账户或子账户被注销前，该账户不得被注销。
  </div>
  <div class="mb-6">2.5.5如您申请注销的章易签账户一旦注销成功，该账户将不再予以恢复。</div>
  <div class="mb-6">2.6 账号安全及数据存储</div>
  <div class="mb-6">2.6.1
    您应当妥善保管您的章易签登录账号和密码、身份信息、手机验证码等信息，对于因本人原因导致密码泄露、身份信息泄露所致的损失，由您自行承担。您应当妥善保管处于您或应当处于您掌控下的这些产品或设备，对于这些产品或设备遗失所致的任何损失，由您自行承担。
  </div>
  <div class="mb-6">2.6.2 基于计算机端、手机端以及使用其他电子设备的用户使用习惯，我们可能在您使用具体产品时设置不同的账号登录模式及采取不同的措施识别您的身份。</div>
  <div class="mb-6 fw-b">2.6.3
    如您发现有他人冒用或盗用您的章易签录账号或有任何其他未经合法授权之情形，可能危及到您的章易签账号安全的，应立即以有效方式通知章易签，向章易签申请暂停相关服务。您理解章易签对您的请求采取行动需要合理期限，在此之前，章易签对已执行的指令及所导致的您的损失不承担任何责任。
  </div>
  <div class="mb-6">2.6.4 您应确保在持续登录本系统时段结束时，以正确步骤退出登录。章易签不能也不会对因您未能遵守本条约定而发生的任何损失、损毁及其他不利后果负责。</div>
  <div class="mb-6">2.6.5
    您确认，您自行对您的章易签账号负责，只有您本人方可使用该账号。该账号不可转让、不可赠与、不可继承，但账号内的相关资产可被依法继承。在您决定不再使用该账号时，您应将该账号内的可用数据导出或删除，并按章易签规定的流程注销。
  </div>
  <div class="mb-6">2.6.6 章易签不对您在本服务中相关数据的删除或储存失败负责，但是您认可章易签账号的使用记录等均以章易签系统记录的数据为准。</div>
  <div class="mb-6">2.6.7 除另有约定外，章易签有权根据实际情况自行决定单个用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份本服务中的相关数据。
  </div>
  <div class="mb-6">2.6.8 如果您停止使用本服务、服务被终止或取消，章易签可以从服务器上永久地删除您的数据。在服务停止、终止或取消后，章易签没有义务向您返还任何数据。</div>
  <div class="mb-6">2.7账号使用规则及限制</div>
  <div class="mb-6">2.7.1
    章易签通过以下三种方式接受来自您的指令：其一，您在章易签平台或其他可使用本服务的平台通过以您的章易签账号及密码等方式登录章易签账号并依照本服务预设流程所修改或确认的指令；其二，您通过您注册时作为该账号名称或者与该账号绑定的专属于您的通讯工具向章易签发送的确认信息；其三，您通过您注册时作为该账号名称或者与该账号名称绑定的其他硬件、终端、软件、代号、编码、代码、其他账号名等有形体或无形体向章易签发送的确认信息。您通过以上三种方式中的任一种向章易签发出指令，都不可撤回或撤销，且成为章易签为您提供电子签名服务的有效指令，您应当对章易签忠实执行上述指令产生的任何结果承担责任。本协议所称绑定，指您的章易签账号与本条上述所称有形体或无形体存在对应的关联关系，这种关联关系使得章易签服务的某些服务功能得以实现，且这种关联关系有时使得这些有形体或无形体能够作为章易签对您的章易签账号的识别和认定依据。
  </div>
  <div class="mb-6">2.7.2
    您在使用章易签服务过程中，本协议内容、操作页面上出现的信息提示或章易签发送到您通讯工具的内容亦是您使用本服务的相关规则，您使用本服务即表示您同意接受本服务的相关规则。您了解并同意章易签有权单方修改服务的相关规则，而无须征得您的同意，服务规则应以您使用服务时的确定的内容为准，您同意并遵守服务规则是您使用章易签服务的前提。
  </div>
  <div class="mb-6 fw-b">2.7.3
    章易签会以发送手机短信、网站消息推送、消息弹窗及接受章易签服务的其他平台消息推送等方式通知您章易签服务进展情况以及提示您进行下一步的操作，但章易签不保证您能够收到或者及时收到该邮件或短信等，且不对此承担任何后果，因此，在交易过程中您应当及时登录到章易签平台或接受章易签服务的其他平台查看和进行电子签名操作。因您没有及时查看和进行电子签名操作而导致的任何纠纷或损失，章易签不负任何责任。
  </div>
  <div class="mb-6 fw-b">2.7.4
    您确认如您代表企业(含其他组织)签署文件的情况下，您向章易签提供其必要的签署信息(包括但不限于签章图形等)已经获得该企业(含其他组织)的合法授权，且您的签署行为获得该企业(含其他组织)的合法授权。您应确保前述授权的完整性、真实性及合法性。您应对因您未能遵守本条约定而发生的任何损失及其他不利后果负责。
  </div>
  <div class="mb-6">2.7.5 您实际使用章易签提供的电子签名服务即视为您与您的签署相对方就电子文件的签署形式已经达成合意，并且您认可签署过程中产生的电子数据。</div>
  <div class="mb-6">2.7.6
    您在使用本服务时应遵守中华人民共和国相关法律法规、您所在国家或地区之法令及相关国际惯例，不将本服务用于任何非法目的，也不以任何非法方式使用本服务，或将本服务用于非章易签许可的其他用途。您不得利用本服务从事侵害他人合法权益之行为，否则章易签有权拒绝提供服务，且您应承担所有相关法律责任，因此导致章易签受损的，您应承担赔偿责任。
  </div>
  <div class="mb-6 fw-b">2.7.7
    您应当知悉，根据《电子签名法》的规定，涉及婚姻、收养、继承等人身关系及涉及停止供水、供热、供气等公用事业服务的电子文件不适用电子签名；法律、行政法规规定的其他不适用电子签名的文件亦不应使用电子签名签署。您有义务自行确认您需签署的文件是否能够使用电子签名，章易签不对您在法律规定的不适用情形中使用电子签名承担任何责任。
  </div>
  <div class="mb-6">2.7.8
    您应当知悉，章易签需履行平台管理义务。您认可章易签有权根据具体的法律、法规、政策及环境变化，设置用户准入政策，并基于前述政策决定是否与您订立合同关系。您对此没有异议，并将配合我们提交您的准入审核材料和接受章易签的审核结果。章易签的用户准入政策请您详见章易签官方网站的公告，或联系章易签客服索取相关政策文件。您认可章易签有权了解您使用章易签产品或服务的背景及目的，您应如实提供章易签所需的真实、全面、准确的信息。
  </div>
  <div class="mb-6 fw-b">2.7.9 您理解并同意，章易签不对因下述任一情况导致的任何损害承担赔偿责任，包括但不限于实际损失、可期待利益、商誉、使用数据等方面的损害(无论章易签是否已被告知损害的可能性)：
  </div>
  <div class="mb-6 fw-b">A. 章易签基于合理的单方判断，包含但不限于认为您已经违反本协议的约定，有权冻结您名下的章易签账号，暂停或终止向您提供部分或全部服务。</div>
  <div class="mb-6 fw-b">B. 章易签发现异常电子签名及合理怀疑电子签名有违反法律、法规规定时，有权不经通知先行冻结您名下的章易签账号，暂停或终止向您提供部分或全部服务。</div>
  <div class="mb-6 fw-b">C. 存在如下情形时，章易签有权冻结您名下的章易签账号，暂停或终止提供部分或全部服务：</div>
  <div class="mb-6 fw-b">1)根据法律法规的规定、本协议的约定或有权机关的要求的；</div>
  <div class="mb-6 fw-b">2)章易签基于单方面合理判断认为账号操作存在异常时；</div>
  <div class="mb-6 fw-b">3)您遭到他人投诉，且对方已经提供了一定证据的；</div>
  <div class="mb-6 fw-b">4)您可能错误地将他人账号进行了身份认证的；</div>
  <div class="mb-6 fw-b">5)章易签履行平台管理义务但您拒不配合提供相关真实完整资料的。</div>
  <div class="mb-6 fw-b">6)您使用章易签服务的行为涉嫌违反国家法律法规及行政规定的：</div>
  <div class="mb-6 fw-b">
    如您代表企业(含其他组织)使用账号，或您实际控制着其他账号的，上述条款对您使用或控制的其他账号同样适用。您应当确保您所代表企业(含其他组织)及您所控制的其他账号的所有权人知悉并同意本条款。</div>
  <div class="mb-6">2.7.10
    如您申请恢复服务、解除账号冻结，您应按章易签要求如实提供相关资料及您的身份证明以及章易签要求的其他信息或文件，以便章易签进行核实。章易签有权依照自行判断来决定是否同意您的申请。您应充分理解您的申请并不必然被允许。您拒绝如实提供相关资料及身份证明的，或未通过章易签审核的，您确认章易签有权决定对该账号保持冻结并暂定或终止提供服务。
  </div>
  <div class="mb-6 fw-b">三、服务内容及费用</div>
  <div class="mb-6">3.1 电子签名服务</div>
  <div class="mb-6">
    您可以通过章易签服务制作并使用专属于您的电子签名。使用电子签名服务需要您成为章易签的用户并完成实名认证，选择适合您的身份认证方式进行数字证书的申请，制作您的电子签章或手绘签名，根据您的意愿完成电子文件的签署。</div>
  <div class="mb-6">3.2 认证服务(含数字证书注册审核服务)</div>
  <div class="mb-6">3.2.1
    您可以通过章易签服务进行个人和企业（含其他组织）的身份认证。如您代表企业（含其他组织）进行身份认证，应当确保您已经获得被代表的企业（含其他组织）的合法授权，并确保其接受本协议的约束。</div>
  <div class="mb-6">3.2.2 章易签提供认证服务需要处理您的个人信息，您同意章易签按照《隐私政策》处理您的个人信息，否则章易签无法向您提供认证服务。</div>
  <div class="mb-6">3.2.3 章易签提供的认证服务为实名认证。实名认证指通过<span
      class="fw-b">信息核验、人脸比对、对公账户打款、校验码回填或人工审核等</span>方式确认您身份的真实性，并反馈认证结果的服务。</div>
  <div class="mb-6">3.2.4 认证结果包括您是否通过身份认证，以及您提供的用来进行身份认证的个人信息、企业或其他组织信息，提供信息的具体范围根据您使用的认证方式确定。</div>
  <div class="mb-6">3.2.5
    为准确核验您的身份，您授权章易签在必要时将您的身份认证信息提供给合法存有您信息的第三方机构进行比对核验。章易签会尽力提升核验结果的准确性，但由于章易签能收集到的信息范围有限，以及行业技术水平及核验手段仍在完善过程中，章易签无法确保核验结果完全准确无误。如您发现核验结果不准确，您可通过客服电话联系章易签进行更正。
  </div>
  <div class="mb-6">3.2.6
    您认可章易签通过认证服务所提供的认证方式对您的身份进行认证，您应当妥善保存能够对您身份进行认证的信息、材料以及设备。如相关信息、材料或设备发生泄露、遗失等失去您控制的情形，您应当及时通知章易签，否则章易签有权利认定相应认证行为由您完成。
  </div>
  <div class="mb-6">3.2.7 可靠的电子签名需要确保电子签名制作数据由您专有和控制，因此身份认证是您使用电子签名的前提。如您不通过身份认证，章易签将无法向您提供电子签名服务。</div>
  <div class="mb-6">3.2.8
    如您需要使用接受章易签服务的其他平台的产品或服务，您授权章易签直接从您处或该平台获取您提供的认证信息进行身份认证。同时，您授权章易签将您的认证结果反馈给该平台以便您能够继续使用该平台的产品或服务。</div>
  <div class="mb-6 fw-b">3.3 数字证书服务</div>
  <div class="mb-6">3.3.1
    数字证书服务指章易签根据您提供的身份信息为您向电子认证服务机构（即CA机构）申请向您颁发数字证书。申请数字证书时您将通过章易签与相应的CA机构订立《数字证书服务协议》，勾选即代表您同意与该CA机构签订该协议。</div>
  <div class="mb-6">3.3.2 <span
      class="fw-b">数字证书至少包含</span>：电子认证服务提供者名称；证书持有人名称；证书序列号；证书有效期；证书持有人的电子签名验证数据；电子认证服务提供者的电子签名；国务院信息产业主管部门规定的其他内容。
  </div>
  <div class="mb-6">
    3.3.3数字证书的使用。您通过章易签平台或接受章易签服务的其他平台签署电子文件时，需要通过申请和使用数字证书的行为代表您对您的身份和签署意愿进行了确认。您通过章易签申请的数字证书只能在有效期限内、在章易签平台或接受章易签服务的其他平台上使用。您勾选本协议或接受章易签服务时，即代表您授权章易签为您保管云证书私钥（UKEY证书用户不适用本授权条款），并在您的专有控制下，根据您的意愿为您调用数字证书，帮助您使用数字证书。您认可前述使用方式，您认可您对数字证书享有独立的使用权。您使用数字证书产生的权利，由您享有；您使用数字证书产生的义务、责任，由您承担。
  </div>
  <div class="mb-6">3.3.4
    吊销数字证书。吊销数字证书分为主动吊销和被动吊销。主动吊销指由您提出吊销申请，经章易签审核通过后吊销证书的情形；被动吊销指当电子认证服务机构或章易签确认您违反证书使用规定、约定或主体消亡等情况发生时，采取吊销证书的手段以停止对该证书的证明。如您为企业用户，您企业分立、合并、解散、注销、宣告破产或倒闭，或被撤销营业执照等主体资格终止的，应于上述情况发生时的<span
      class="fw-b">5个工作日</span>内通过书面形式告知章易签申请吊销数字证书，并终止使用该数字证书。如您对数字证书的使用违反法律法规或严重违反本协议约定，章易签有权向电子认证服务机构申请吊销您的数字证书，并要求您承担相应的责任。您的数字证书被吊销的，数字证书的有效期限届满。
  </div>
  <div class="mb-6">3.3.5
    数字证书私钥在有效期内损毁、丢失、泄露或失去您专有控制的，您应当及时申请办理吊销手续，吊销自手续办妥时起生效，吊销生效前发生的损失由您承担。您应在知道或应当知道前述情况发生时及时告知章易签，协助完成吊销该证书的事项，并终止使用该证书。
  </div>
  <div class="mb-6">3.3.6 章易签将对您的数字证书相关信息进行保存，信息保存期限至少为您的数字证书<span class="fw-b">失效后5年。</span></div>
  <div class="mb-6">3.3.7
    依赖方使用。依赖方指信赖数字证书所证明的基础信息并依此进行业务活动的个人或企业（含其他组织）。依赖方接收到经您签名的电子文件后，可以使用数字证书上的公钥验证签名，以此判断其收到的信息中您的电子签名是否有效。依赖方能够通过接受您的签名信息获取到数字证书中<span
      class="fw-b">包含的您的个人信息，如您的姓名/名称及证件号码。</span></div>
  <div class="mb-6">3.3.8 如您对通过章易签服务申领的数字证书有任何疑问，可通过客服电话联系章易签进行咨询。</div>
  <div class="mb-6">3.4电子合同签约服务</div>
  <div class="mb-6">采用电子签名、时间戳等技术，实现对数据电文的安全保护和签章信息的图形化展现，明确电子文件签署者各方的身份信息、签署行为的过程以及签署完成后文件改动，从而使用户在线完成文件的签署。
  </div>
  <div class="mb-6">3.5合同及证据托管、下载服务</div>
  <div class="mb-6">用户签署的文件在完成后，将由章易签对签署完成的文件本身以及签署的流程信息等内容进行保存，签署任务发起方及文档签署方可在章易签平台随时下载签署完成的合同。</div>
  <div class="mb-6">3.6公证服务</div>
  <div class="mb-6">章易签平台已与国内公证机构合作，用户可通过章易签平台签署的相关文件同步存储于公证机构，<span
      class="fw-b">用户可向公证机构申请出具公证文书</span>。用户申请公证文书时，相关认证信息（如合同另一签署方的信息）会披露给出证发起方。</div>
  <div class="mb-6">3.7服务费用</div>
  <div class="mb-6">3.7.1
    在您使用章易签服务时，章易签宝有权向您收取服务费用。章易签拥有制定及调整服务费用之权利，具体服务费用以您使用章易签服务时本网站产品页面上所列之收费方式公告或您与章易签达成的其他书面协议为准。</div>
  <div class="mb-6">3.7.2
    您应在订单提交后及时按约定完成支付。部分服务或优惠措施可能有时间或条件限制、库存限制或者活动数量等限制，订单提交后，若发生您未及时付款、或付款期间出现数量不足或已达到限量等情况的，您将可能无法使用相关服务或享受相关优惠。
  </div>
  <div class="mb-20">3.7.3 如您未在约定期限足额支付服务费用，章易签有权暂停或停止向您提供部分或全部服务。</div>
  <div class="mb-6 fw-b">四、免责及限制条款</div>
  <div class="mb-6 fw-b">4.1服务不可用</div>
  <div class="mb-6 fw-b">4.1.1 未经身份认证或数字证书被吊销而导致电子签名服务不可用的，章易签不承担任何责任。</div>
  <div class="mb-6 fw-b">4.1.2 由于系统本身问题、相关作业网络连接问题或其他不可抗拒因素，造成本服务无法提供的，章易签不承担任何责任。</div>
  <div class="mb-6 fw-b">4.1.3 章易签服务因下列状况无法正常运作，使您无法使用各项服务时，章易签不承担损害赔偿责任，该状况包括但不限于：</div>
  <div class="mb-6 fw-b">A.章易签平台系统停机维护期间；</div>
  <div class="mb-6 fw-b">B.电信设备出现故障不能进行数据传输的；</div>
  <div class="mb-6 fw-b">C.因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成章易签服务障碍不能执行业务的；</div>
  <div class="mb-6 fw-b">D.由于黑客攻击、网络供应部门技术调整或故障、网站升级等原因而造成的服务中断或者延迟。</div>
  <div class="mb-6 fw-b">4.2服务终止</div>
  <div class="mb-6 fw-b">4.2.1
    如您购买的具体服务含有存储功能的，在该服务到期或终止后，对于您存储在该服务中的数据等任何信息，章易签将根据该服务的服务规则为您保留相应期限。您应承担保留期限内产生的费用（若有），并按时结清费用、完成全部数据的迁移。保留期限届满后，您的前述信息将被删除，章易签不对此承担任何责任。
  </div>
  <div class="mb-6 fw-b">4.2.2
    章易签有权根据自身运营安排，随时调整、终止部分或全部服务（包括但不限于对服务进行下线、迭代、整合等），章易签不对此承担任何责任。但是，章易签应提前至少30天通知您，以便您做好相关数据的转移备份以及业务调整等，以保护您的合法权益。
  </div>
  <div class="mb-6 fw-b">4.3 因您使用章易签服务签署电子文件所产生的交易风险应由您与交易相对方承担。</div>
  <div class="mb-6 fw-b">4.4
    除章易签或一览数字与您另达成关于违约责任赔偿的书面约定外，因章易签电子签名技术瑕疵导致您签署的电子文件被法院、仲裁委生效判决、裁决认定为无效的，章易签承担损失赔偿责任，章易签所承担的违约赔偿责任总额不超过<span
      style="color:red;">5万元</span>。</div>
  <div class="mb-6 fw-b">4.5
    在法律允许的情况下，章易签对于由履行本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、商誉损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。
  </div>
  <div class="mb-6 fw-b">4.6 章易签仅对本协议中列明的责任承担范围负责。</div>
  <div class="mb-6 fw-b">4.7
    因您违反本协议约定或平台使用规则，或因您违反了法律、法规、规章、政府规范性文件或侵害了第三方的权利，使章易签遭受损失的，您必须承担相应的赔偿责任（包括但不限于律师费、诉讼费的赔偿）。</div>
  <div class="mb-20 fw-b">4.8 除本协议另有规定外，在任何情况下，章易签或一览数字对本协议所承担的违约赔偿责任总额不超过向您收取的当次服务费用总额。</div>
  <div class="mb-6 fw-b">五、隐私制度</div>
  <div class="mb-20">章易签十分注重对用户隐私的保护。具体详见《隐私政策》。</div>
  <div class="mb-6 fw-b">六、商标及知识产权</div>
  <div class="mb-6">
    6.1除非另有约定或我方另行声明，章易签平台内的所有内容(用户依法享有版权的内容除外)、技术、软件、程序、源代码、数据及其他信息(包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档)的所有知识产权(包括但不限于版权、商标权、专利权、商业秘密等)及相关权利，均归一览数字或一览数字关联公司所有。未经一览数字许可，任何人不得擅自使用(包括但不限于复制、传播、展示、镜像、上载、反编译、下载、修改、出租)。
  </div>
  <div class="mb-6">
    6.2章易签平台的Logo、文字、图形及其组合，以及章易签平台的其他标识、徵记、产品和服务名称均为一览数字的商标（无论是否进入商标注册程序），未经一览数字书面授权，任何人不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用或作其他处理。
  </div>
  <div class="mb-20">6.3尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。</div>
  <div class="mb-6 fw-b">七、法律适用与管辖</div>
  <div class="mb-20">
    本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。因本协议产生之争议，均应依照中华人民共和国法律予以处理，并由被告住所地人民法院管辖。</div>
  <div class="mb-6 fw-b">八、其他</div>
  <div class="mb-6">8.1通知送达</div>
  <div class="mb-6">
    8.1.1本协议条款及任何其他的协议、公告或其他关于您使用本服务账号及服务的通知，您同意章易签使用电子方式通知您。电子方式包括但不限于以短信等一切您留存的联系方式及平台公示、系统消息等形式。</div>
  <div class="mb-6">
    8.1.2章易签的通知如以公示或系统消息方式做出，一经在公示或发出即视为已经送达。除此之外，其他向您个人发布的具有专属性的通知将由章易签在您认证时或者认证后变更用户信息时绑定的手机号码发送，一经发送即视为已经送达。请您密切关注手机中的短信信息。因信息传输但未在章易签的认证信息中填写您变更后的信息等原因导致您未在前述通知发出当日收到该通知的，章易签不承担责任。
  </div>
  <div class="mb-6">8.1.3您同意章易签利用在本平台登记的联系方式与您联络并向您传递相关的信息，包括但不限于行政管理方面的通知、产品信息、有关您使用章易签的通讯以及针对性的广告等。</div>
  <div class="mb-6">8.2如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</div>
  <div class="mb-6">8.3本协议中的标题仅为方便而设，不具有任何单独的法律效力。</div>
  <div class="mb-6">8.4一览数字对本协议享有最终的解释权。</div>
</template>