<template>
  <div>本版发布日期：2022年9月30日 生效</div>
  <div>生效日期：2022年9月30日</div>
  <div class="mt-20 mb-20 fw-b">提示条款</div>
  <div class="mb-20">您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施。鉴于此，
    一览数字科技(江苏)有限公司(以下简称“我们”或“一览数科”)制定本《隐私政策》(下称“本政策/本隐私政策”)并提醒您：</div>
  <div class="mb-20">本政策适用于一览数字科技(江苏)有限公司提供的所有产品和服务。
    <span
      class="fw-b">希望您在使用我们的产品和服务前仔细阅读并确认您已经充分知悉并理解本政策所陈述的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。一旦您开始使用或在我们更新本隐私政策后继续使用我们的产品和服务，即表示您已充分理解并同意本政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。</span>
  </div>
  <div class="mb-20">
    <span
      class="fw-b">在使用各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。</span>如对本政策内容有任何疑问、意见或建议，您可通过提供的各种联系方式与我们联系。
  </div>
  <div class="mb-6 fw-b">第一部分 定义</div>
  <div class="mb-6"><span class="fw-b">个人信息</span>：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</div>
  <div class="mb-6"><span class="fw-b">个人敏感信息</span>：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。通常情况下，
    14岁以下(含)儿童的个人信息和生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息属于个人敏感信息。</div>
  <div class="mb-20"><span class="fw-b">个人信息删除</span>：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</div>
  <div class="mb-6 fw-b">第二部分 隐私政策</div>
  <div class="mb-6">本隐私政策部分将帮助您了解以下内容：</div>
  <div class="mb-6">一、我们如何收集和使用您的个人信息</div>
  <div class="mb-6">二、我们如何使用Cookie和同类技术</div>
  <div class="mb-6">三、我们如何共享、转让、公开披露您的个人信息</div>
  <div class="mb-6">四、我们如何保护您的个人信息</div>
  <div class="mb-6">五、您的权利</div>
  <div class="mb-6">六、我们如何处理未成年人的个人信息</div>
  <div class="mb-6">七、本隐私政策如何更新</div>
  <div class="mb-6">八、如何联系我们</div>
  <div class="mb-6 fw-b">一、我们如何收集和使用您的个人信息</div>
  <div class="mb-6">在您使用我们的产品和服务时，我们可能需要收集和使用的您的个人信息包括如下两种：</div>
  <div class="mb-6 fw-b">1.为实现向您提供我们的产品和服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品和服务；</div>
  <div class="mb-6 fw-b">
    2.为实现向您提供我们产品和服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品和服务的基本功能。</div>
  <div class="mb-6 fw-b">您理解并同意：</div>
  <div class="mb-6 fw-b">
    1.我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；
  </div>
  <div class="mb-6 ">
    <span
      class="fw-b">2.为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。</span>在此过程中，如果您有任何疑问、意见或建议的，您可通过提供的各种联系方式与我们联系，我们会尽快为您作出解答。
  </div>
  <div class="mb-6 fw-b">
    3.请您注意，您的交易相对方、您访问的第三方网站经营者、通过我们接入的第三方服务可能有自己的信息保护政策；当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的 Cookie 或网络
    Beacon，这些 Cookie 或网络Beacon
    不受我们的控制，且它们的使用不受本政策的约束。我们会努力去要求这些主体对您的个人信息采取保护措施，建议您与他们联系以获得关于他们的信息保护政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
  </div>
  <div class="mb-20">我们会为实现本政策下述的各项功能，收集和使用您的个人信息：</div>
  <div class="mb-6 fw-b">(一) 实现签发文件功能必须收集的信息</div>
  <div class="mb-6">1.账户注册：当您首次注册章易签账户时，您需要提供您的手机号码获取验证码并创建登录密码，上述信息为您注册章易签账号所必需，若您不提供上述信息，将影响您全面使用章易签产品/服务。</div>
  <div class="mb-6">
    2.实名认证：在您使用章易签账户发起或签署合同前您需要进行实名认证。根据你所选择的实名认证方式，提供您的真实姓名、身份证号码、人脸识别视频、面部照片、身份证照片/护照等证件照片、银行卡信息、银行预留手机号、实名手机号码、个人手写签名或其他您提供的认证信息。收集此类信息是为了满足相关法律法规的实名制要求，若您不提供此类信息，您将无法完成实名认证，亦无法发起或签署合同，但不会影响您使用章易签的基本浏览功能。
  </div>
  <div class="mb-6">
    如您是法人单位，我们还可能会收集您的公司营业执照信息或组织机构代码证信息/照片、公司住所地、对公银行账号、法定代表人的姓名及有效证件号码及身份证正反面照片、法定代表人个人手机号码。法人单位如授权员工作为经办人办理电子签约业务，我们会要求该员工向我们提交授权文件，根据授权文件我们可能会收集员工的姓名、个人手机号码、身份证号码用于确认您办理电子签约业务的权限。法定代表人及经办人收集的信息参照上述条款。
  </div>
  <div class="mb-6">3.数字证书信息：实名认证通过后会由CA机构为您颁发数字证书，我们会保留您的数字证书信息，此信息包含<span
      class="fw-b">您的姓名、身份证件信息，以及为您申请的数字证书序列号</span>，以便供您在签发文件时调用。若您不授权我们保存此信息，您将无法正常使用我们的合同签订服务。</div>
  <div class="mb-6">4.签署验证信息：在您使用电子签名时，我们会处理您的真实姓名、身份证号码、或实名手机号码，以核实是您本人在进行电子签名操作。若您不授权我们进行处理，您将无法实施电子签名。</div>
  <div class="mb-6">
    5.签署文件信息：我们会收集您主动上传至我们的材料，包含合同原文、哈希值或其他书面材料，该文件或材料可能涉及您的隐私信息或商业秘密。我们会对上述信息进行安全保存。除非您主动提交合同审阅的服务，我们不会主动收集或分析这些信息。<span
      class="fw-b">若您提供的是他人的信息，包括姓名、电话号码、身份证号等个人隐私信息，您需确认获得对方授权且保证对方也知晓并同意受本隐私政策之约束，因您提供的信息造成他人损害的，将由您自行承担责任。同时，如果他人提出相反意见或者向我们明示不愿受本隐私政策约束的，我们将删除相关信息。</span>
  </div>
  <div class="mb-6 fw-b">(二) 您在使用章易签过程中，我们会收集的信息</div>
  <div class="mb-6">当您使用章易签时，为保障我们的服务连续性以及保障您的账户安全，我们会收集以下信息：</div>
  <div class="mb-6">1.设备或软件信息，例如您的移动设备型号、网页浏览器或您用于接入我们的服务的其他程序所提供的配置信息、您的IP地址和您的移动设备所用的版本和设备识别码。</div>
  <div class="mb-6">2.日志信息，您在使用我们服务时接入网络的方式、类型和状态、登录时间、操作日志、服务日志等与章易签相关的日志信息。
    请您注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标化处理。
  </div>
  <div class="mb-6">3.设备权限：我们可能基于您的允许获得某些设备权限，包括：</div>
  <div class="mb-6">(1)相机/摄像头权限，用于人脸识别等特定的服务场景；</div>
  <div class="mb-6">(2)访问照片权限，用于特定服务场景下您自行选择需要上传的保存在您相册内的图片。</div>
  <div class="mb-6">(3)访问电脑本地文件权限，用于特定服务场景下您自行选择需要上传的保存在您相册内的图片，视频，文字，音乐等。</div>
  <div class="mb-6">(4)存储权限，获取存储权限是为了在进行本地文件发起签署时用于读取文件并将文件作为签署的载体，在合同下载时用于向存储写入下载的合同文件。</div>
  <div class="mb-6 fw-b">
    请您注意，以上权限您可以根据需要选择开启或关闭，您开启任一权限即代表您授权我们可以收集或使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但不会影响此前基于您的授权所进行的信息收集及使用。
  </div>
  <div class="mb-6">4.当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</div>
  <div class="mb-6">(三) 信息用途</div>
  <div class="mb-6">1.为便于您注册、登录、使用本产品各项服务。</div>
  <div class="mb-6">2.为提升您的服务体验及改善我们的产品和服务。</div>
  <div class="mb-6">3.为监管检查、公司审计、稽核所需。</div>
  <div class="mb-6">4.法律法规允许的其他用途。</div>
  <div class="mb-6">您提供的上述信息，将在您使用我们服务期间持续授权我们使用。我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，需事先征求您的同意。</div>
  <div class="mb-6">本隐私政策列明的内容并不能完整罗列并覆盖未来可能实现的所有功能和业务场景，我们将基于本隐私政策未载明的其他特定目的收集您的信息，但需事先征求您的同意。</div>
  <div class="mb-6 fw-b">二、我们如何使用 Cookie 和同类技术</div>
  <div class="mb-6">
    为确保产品和服务正常运转、为您获得更轻松的访问体验，我们可能会通过采用各种技术收集和存储您访问产品和服务的相关数据，我们会在您的移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好数据。
  </div>
  <div class="mb-6">您可根据自己的偏好管理或删除Cookie。您可以清除移动设备上保存的所有Cookie。</div>
  <div class="fw-b">三、我们如何共享、转让、公开披露您的个人信息</div>
  <div class="mb-6 fw-b">(一)共享</div>
  <div class="mb-6">我们不会与一览数科以外的公司、组织和个人共享您的个人信息，但以下情况除外：</div>
  <div class="mb-6">1. 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</div>
  <div class="mb-6">2. 在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。 </div>
  <div class="mb-6">3. 应您合法需求，协助处理您与他人的纠纷或争议；</div>
  <div class="mb-6">
    4.与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如：您的个人身份信息及签约信息需传输至CA机构、公证机构、第三方身份认证机构等，由上述权威机构出具相应证明文件或处理案件等目的使用并保存。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息.我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。
  </div>
  <div class="mb-6">
    (1)分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息(指可以识别您身份的信息，例如姓名或手机号码，通过这些信息可以联系到您或识别您的身份)与分析服务的合作伙伴共享。我们会向这些合作伙伴提供有关有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。
  </div>
  <div class="mb-6">
    (2)供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利或进行学术研究和调查。</div>
  <div class="mb-6">对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</div>
  <div class="mb-6 fw-b">(二)转让</div>
  <div class="mb-6"> 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</div>
  <div class="mb-6">1. 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</div>
  <div class="mb-6">2.
    在一览数科服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
  </div>
  <div class="mb-6 fw-b">(三)公开披露</div>
  <div class="mb-6">我们仅会在以下情况下，公开披露您的个人信息：</div>
  <div class="mb-6">1. 获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</div>
  <div class="mb-6">2.
    根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。
  </div>
  <div class="mb-6 fw-b">(四)收集、共享、转让、公开披露个人信息时事先征得授权同意的例外</div>
  <div class="mb-6">以下情形中，收集、共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</div>
  <div class="mb-6">1. 与国家安全、国防安全有关的;</div>
  <div class="mb-6">2. 与公共安全、公共卫生、重大公共利益有关的;</div>
  <div class="mb-6">3. 与犯罪侦查、起诉、审判和判决执行等有关的；</div>
  <div class="mb-6">4. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;</div>
  <div class="mb-6">5. 您自行向社会公众公开的个人信息；</div>
  <div class="mb-6">6. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道</div>
  <div class="mb-6">
    根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</div>
  <div class="fw-b">四、我们如何保护您的个人信息</div>
  <div class="mb-6">
    (一)我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高个人信息的安全性;我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
  </div>
  <div class="mb-6">(二)我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。</div>
  <div class="mb-20">(三)我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
  </div>
  <div class="mb-20">如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络一览数科的客服，以便我们根据您的申请采取相应措施。
  </div>
  <div class="mb-20">我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
  </div>
  <div class="mb-20">
    请您理解，由于技术的限制以及风险防范的局限，即使我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需了解，您接入并使用我们服务时所用的系统和通讯网络，有可能因我们可控范围之外的原因而发生问题。
  </div>
  <div class="mb-6">
    (四)在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
  </div>
  <div class="mb-6 fw-b">五、您如何管理您的个人信息</div>
  <div class="mb-6">您可以通过以下方式访问及管理您的个人信息：</div>
  <div class="mb-6 fw-b">(一)访问您的个人信息</div>
  <div class="mb-6">您有权访问您的个人信息，法律法规规定的例外情况除外。具体访问路径以一览数科的相应产品和服务内提供的为准。</div>
  <div class="mb-6 fw-b">(二)更正或补充您的个人信息</div>
  <div class="mb-6">当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。</div>
  <div class="mb-6 fw-b">(三)删除您的个人信息</div>
  <div class="mb-6">1.如果我们处理个人信息的行为违反法律法规；</div>
  <div class="mb-6">2.如果我们收集、使用您的个人信息，却未征得您的明确同意;</div>
  <div class="mb-6">3.如果我们处理个人信息的行为严重违反了与您的约定；</div>
  <div class="mb-6">4.如果您不再使用我们的产品或服务，或您主动注销了账号</div>
  <div class="mb-6">5.如果我们永久不再为您提供产品或服务。</div>
  <div class="mb-6">若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。</div>
  <div class="mb-6">当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</div>
  <div class="mb-6 fw-b">(四)改变您授权同意的范围</div>
  <div class="mb-6">每个业务功能需要一些基本的个人信息才能得以完成。除此之外，对于额外个人信息的收集和使用，您可以与客服联系给予或收回您的授权同意。</div>
  <div class="mb-6">当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</div>
  <div class="mb-6 fw-b">(五)个人信息主体注销账户</div>
  <div class="mb-6">您随时可以注销此前注册的账户，您可以通过以下方式自行操作：</div>
  <div class="mb-6">【个人中心】-【账号注销】-【注销】申请注销您的账户，在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。</div>
  <div class="mb-6 fw-b">(六)约束信息系统自动决策</div>
  <div class="mb-6">
    在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害一览数科商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
  </div>
  <div class="mb-6 fw-b">(七)响应您的上述请求</div>
  <div class="mb-6">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。 我们将在15天内做出答复。如您不满意，还可以通过客服发起投诉。
  </div>
  <div class="mb-6">
    对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段(例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
  </div>
  <div class="mb-6">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</div>
  <div class="mb-6">1. 与国家安全、国防安全有关的;</div>
  <div class="mb-6">2. 与公共安全、公共卫生、重大公共利益有关的；</div>
  <div class="mb-6">3. 与犯罪侦查、起诉、审判和执行判决等有关的;</div>
  <div class="mb-6">4. 有充分证据表明个人信息主体存在主观恶意或滥用权利的;</div>
  <div class="mb-6">5. 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</div>
  <div class="mb-6">6. 涉及商业秘密的。</div>
  <div class="fw-b">六、我们如何处理未成年人的个人信息 </div>
  <div class="mb-6">
    章易签服务对象主要为18周岁以上的完全民事行为能力人。在使用章易签过程中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
  </div>
  <div class="mb-6">
    我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、储存、共享、转让或披露未成年人的个人信息；如果我们发现在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。
  </div>
  <div class="mb-6">若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过公司本隐私政策公示的联系方式与我们联系。</div>
  <div class="fw-b">七、本隐私政策如何更新</div>
  <div class="mb-6">我们的隐私政策可能变更。</div>
  <div class="mb-6">未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。有关隐私政策的任何变更，我们会通网站公告或以其他适当方式提醒您相关内容的更新。</div>
  <div class="mb-6">
    为给您带来更好的产品/服务体验，我们在持续努力改进我们的技术，我们可能会不定期升级或优化功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。
  </div>
  <div class="fw-b">八、如何联系我们</div>
  <div class="mb-6">如您对本隐私政策或您个人信息的相关事宜有任何问题、意见、建议、投诉，请通过访问在线客服与我们联系，我们将在15天内回复您的请求。</div>
  <div class="mb-6 fw-b">客服电话：025-58753865</div>
  <div class="mb-6 fw-b">工作时间：9:00-17:00</div>
</template>