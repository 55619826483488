<template>
  <el-dialog class="agreementbox" v-model="dialogVisible" :close-on-click-modal="false" width="732px">
    <template #header="{ close, titleId, titleClass }">
      <div class="dp-f headerbox jc-c">
        请滑动阅读协议内容
      </div>
    </template>
    <div class="agreement">
      <div class="titlebox dp-f jc-c">
        {{ dialogVisibleTitle }}
      </div>
      <div ref="agreeBoxRef" class="autobox pt-18" @scroll.passive = "getScroll">
        <!-- 隐私政策 -->
        <template v-if="state.typeIndex == 0">
          <Policy></Policy>
        </template>
        <!-- 用户协议 -->
        <template v-if="state.typeIndex == 1">
          <user></user>
        </template>
        <!-- 电子认证服务协议 -->
        <template v-if="state.typeIndex == 2">
          <electron></electron>
        </template>
        <!-- 个人信息保护政策 -->
        <template v-if="state.typeIndex == 3">
          <authentication></authentication>
        </template>
        <!-- 风险知情书 -->
        <template v-if="state.typeIndex == 4">
          <div class="mb-20">北京数字认证股份有限公司（简称“数字认证公司”）是获得工业和信息化部许可的电子认证服务机构，全面按照国家主管部门关于开展电子认证服务的要求，安全、合规、有序的开展电子认证服务。
          </div>
          <div class="mb-20">
            数字证书作为网络身份凭证，申请人使用数字证书办理网上业务（如网上申报、注册公司、签署电子合同等），将承担相应的法律责任。为了避免申请人因证书使用和保管不当引发的法律风险，现将办理、使用和保管数字证书的注意事项提示如下：
          </div>
          <div class="mb-20 fw-b">一、申请办理数字证书是您本人的真实意愿，本人充分知悉办理数字证书的真实用途。</div>
          <div class="mb-20">二、申请数字证书时，须认真、仔细阅读《电子认证服务协议》，知悉并确认相关的权利与义务。</div>
          <div class="mb-20">三、使用数字证书进行的电子签名，与本人手写签名或盖章具有同等的法律效力。</div>
          <div class="mb-20 fw-b">四、证书申请人切勿将数字证书交由他人保管，避免他人利用您的数字证书冒用您的网络身份从事非法活动。</div>
          <div class="mb-20 fw-b">五、请妥善保管数字证书及口令，切勿将数字证书口令告知他人。如果您的数字证书损毁、丢失、或被不当使用，应第一时间通知数字认证公司注销数字证书。</div>
          <div class="mb-20">如遇到数字证书办理、使用等咨询，以及投诉问题，请拨打数字认证公司客户服务热线：010-58515511。</div>
        </template>
        <!-- 数字证书申请表 -->
        <template v-if="state.typeIndex == 5">
          <certificates></certificates>
        </template>
        <template v-if="state.typeIndex == 6">
          <electronWo />
        </template>
      </div>
    </div>
    <div class="ta-c mt-16 ftit">阅读并同意全部协议后，可继续操作</div>
    <template #footer>
      <span class="dialog-footer dp-fc">
        <div class="button button1 mr-26" @click="dialogVisible = false, clearInterval()">拒绝</div>
        <div class="button button2"
          @click="emit('typeIndexEmit', state.typeIndex), state.typeIndex == 0 ? emit('Emit') : '', dialogVisible = false, clearInterval()"
          v-if="state.readTime == 0 && state.scrollBottom">同意</div>
        <div class="button button3" v-else>阅读{{ state.readTime }}</div>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, onUnmounted, defineEmits, nextTick, onMounted } from 'vue'
import Policy from './agreement/Policy.vue'
import user from './agreement/user.vue'
import electron from './agreement/electron2.vue'
import electronWo from './agreement/electron_wo.vue'
import authentication from './agreement/authentication.vue'
import certificates from './agreement/certificates.vue'
import qs from "qs";
const dialogVisible = ref(false)//弹框显示
const dialogVisibleTitle = ref()//弹框标题
const agreeBoxRef = ref()
// const readBox = ref()
const emit = defineEmits(['Emit', 'typeIndexEmit'])
const state = reactive({
  readTime: 5,//强制阅读时间
  setIntervalObj: {},//定时器对象
  typeIndex: 0,//类型下标 0 隐私政策 1 用户协议
  scrollBottom: true,  //是否滚到到最下方
  scroll: null 
})
// 清除定时器
const clearInterval = (() => {
  window.clearInterval(state.setIntervalObj);
})
const getScroll = (event) => {
  state.scroll = event
  let bottom = event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop
  if (bottom <= 0) {
    console.log("getScroll is bottom")
    state.scrollBottom = true
  }
}
// 执行循环定时器
const use_setInterval = (() => {
  state.setIntervalObj = setInterval(() => {
    state.readTime--
    if (state.readTime == 0) {
      clearInterval()
    }
  }, 1000)
})
onMounted(() => {
  // console.log("onMounted: ", readBox.scrollHeight)
})
onUnmounted(() => {
  clearInterval()
})
const show = (type = 0, title = '') => {
  clearInterval()
  if (state.scroll) {
    state.scroll.target.scrollTop = 0;
  }
  state.typeIndex = type
  dialogVisibleTitle.value = title
  dialogVisible.value = true
  state.readTime = 5
  use_setInterval()
  nextTick(() => {
    unref(agreeBoxRef).scrollTo({ top: 0})
  })
}
const windowOpen = (url) => {
  window.open(url, '_blank')
}
defineExpose({
  show,
});
</script>
<style lang="scss" scoped>
iframe {
  border: none;
  background-color: transparent;

  .product_contents {
    background-color: transparent !important;
  }
}

.ftit {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.button {
  width: 146px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
}

.button1 {
  background: #FFFFFF;
  border: 1px solid #EEC774;
}

.button2 {
  background: #EEC774;
}

.button3 {
  cursor: not-allowed;
  background-color: rgba(238, 199, 116, 0.2);
  color: rgba(51, 51, 51, 0.2);
}

.agreement {
  width: 100%;
  height: calc(100vh - 400px);
  background: #F3F5F7;
  border-radius: 24px;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  .titlebox {
    font-size: 16px;
    padding-top: 14px;
    padding-bottom: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    border-bottom: 1px solid #D8D8D8;
  }

  .autobox {
    height: calc(100% - 48px);
  }
}
</style>