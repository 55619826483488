<template>
  <div class="mb-20">    
    为明确双方的权利和义务，订户（甲方）和沃通电子认证服务有限公司 （乙方）就“电子签名数字证书”（以下简称“数字证书”）相关事项达成本协议，以资共同遵守。
  </div>
  <div class="mb-20">
    本协议构成订户与沃通电子认证服务有限公司之间的权利义务约定，为维护订户的权益，订户在同意本协议之前，应仔细阅读本协议的全部内容，订户在线勾选同意即视为答署本协议并同意接受和愿意遵守本协议的所有条款。
  </div>
  <div class="mb-20">
    甲方同意委托一览数字科技（江苏）有限公司，即“一览”，代甲方向乙方申请数字证书，数字证书有效期为12月，证书服务费为0元/张/年。
  </div>
  <div class="mb-20">
    甲方同意提交甲方的姓名/名称、证件号 （如身份证号、统一社会信用代码等）等信息用于向乙方申请数字证书。
  </div>
  <div class="mb-20">
    甲方确认所提交的身份信息真实、有效，并授权乙方至少在数字证书失效后5年内保存甲方的上述信息，法律、行政法规另有规定的依其规定。
  </div>
  <div class="mb-30">
    甲方知悉数字证书将绑定甲方的身份信息，其作出的电子签名将代表甲方的真实意思表示，数据电文经过电子签名后即代表甲方知悉并认可其中所载内容。
  </div>
  <div class="mb-20">
    为方便甲方与“一览”开展活动，甲方授权“一览”在乙方发放数字证书后，将甲方的数字证书托管在“一览”。
  </div>
  <div class="mb-20">
    “一览”将按照国家法律法规等规定严格履行安全保管义务，仅在获得甲方的授权后投照授权内容调用数字证书制作电子签名。
  </div>
  <div class="mb-20">
    甲方应当在接受之前登录乙方官网：
    <span class="cu-p" style="color:#128bed;"
      @click="windowOpen('https://www.wotrus.com/ca')">https://www.wotrus.com/ca</span>
    ，阅读《沃通电子认证服务有限公司电子认证服务协议》、《电子认证业务规则》以了解有关数字证书的事项及甲方和乙方之间的权利义务内容。
 </div> 
  <div class="mb-20">
      一旦甲方接受本条款，则意味着甲方与乙方之间成立数字证书服务合同关系。甲方同意当甲方与乙方对有关数字证书的服务事项发生争议时，将争议提交至乙方所在地人民法院管辖。
  </div> 
</template>
<script setup>
const windowOpen=(url)=>{
  window.open(url, '_blank')
}
</script>